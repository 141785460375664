import React, { useState, useEffect, useCallback } from 'react';
import { useCookies } from "react-cookie";
import { apiUrl } from '../../config.js';
import { GiHamburgerMenu, GiCancel } from 'react-icons/gi'
import { RiArrowDropRightLine } from 'react-icons/ri'
import ContinuumLogo from '../../images/c247plus.png'
import { BsXLg, BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Navbar, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import '../../Styles/DefaultStyles.scss';
import './NavMenu.scss';
import defaultAvatar from '../../images/default-avatar.svg';
import logo from '../../images/logo.png';
import { useAccessGroup } from '../../AccessGroupContext.js';
import { useParams } from 'react-router';
import { useFeatureFlag } from '../../FeatureFlagContext.js';

// Navigation icons
import billingIcon from '../../images/nav-icons/billing-icon.svg';
import dashIcon from '../../images/nav-icons/dashboards-icon.svg';
import deploymentIcon from '../../images/nav-icons/deployment-icon.svg';
import formIcon from '../../images/nav-icons/form-icon.svg';
import plannerIcon from '../../images/nav-icons/planner-icon.svg';
import reportsIcon from '../../images/nav-icons/reports-icon.svg';
import screeningIcon from '../../images/nav-icons/screening-icon.svg';
import settingsIcon from '../../images/nav-icons/settings-icons.svg';
import supportIcon from '../../images/nav-icons/support-icon.svg';
import taskIcon from '../../images/nav-icons/task-management-icon.svg';
import logoutIcon from '../../images/nav-icons/logout-icon.svg';

export function NavMenu({ change, PasstoLayout, ontoggle, isDark }) {
    NavMenu.displayName = NavMenu.name;
    const { accessGroups, setAccessGroups } = useAccessGroup();
    const { featureFlags, setFeatureFlags } = useFeatureFlag();
    const location = useLocation();
    const [isIndexRoute, setIsIndexRoute] = useState(location.pathname === '/');
    const [isDarkflag, setisDarkflag] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [username, setUsername] = useState("");
    const [accessGroup, setAccessGroup] = useState([]);
    const [cookies] = useCookies(["jwt"]);
    const jwt = cookies['jwt'];
    const UserId = cookies['userid'];
    const [homePage, setHomePage] = useState(cookies['HomePage']);
    
    const [profiledata, setprofiledata] = useState({ firstName: '', lastName: '', Email: '', Password: '', ProfilePic: '' });

    useEffect(() => {
        // Update isIndexRoute whenever the location changes
        setIsIndexRoute(location.pathname === '/');
    }, [location]);

    //Get User data
    useEffect(()  => {
        fetchUsername();
        GetDefualtMode();
        fetchAccessGroups();
        fetchFeatureFlags();
        fetchHomePage();
        const isNotManagerReport = !window.location.pathname.includes('/ManagerReport');
      
        if (jwt) {
            fetchProfileData();
        }
        else if (process.env.REACT_APP_APIURL === 'PRODUCTION' && !jwt && isNotManagerReport) {
            window.location.href = window.location.origin;
        }
        
        async function GetDefualtMode() {
            try {
                var response = await fetch(`${apiUrl}/Login/GetColourMode`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `bearer ${jwt}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(true)
                });

                const res = await response.json();
                if (res.message != null) {

                    window.alert(res.message);

                }
                else {
                    setisDarkflag(res.data[0].darkMode);
                }
            }
            catch (error) {
                console.log(error);
            }

        }
       
    }, []);

useEffect(() => {
        PasstoLayout(isDarkflag);
    
}, [isDarkflag]);
    const fetchUsername = async () => {

        try {
            var response = await fetch(`${apiUrl}/Login/GetUserDetailsFromJWT`, {
                headers: { "Authorization": `bearer ${jwt}` }
            })

            if (!response.ok) {
                throw new Error("Failed to fetch username");
            }

            const data = await response.json();

            // If superadmin set username otherwise fullname
            setUsername(data.username);


        } catch (error) {
            console.error("Error fetching username:", error);
        }
    };

    const fetchAccessGroups = async () => {
        try {
            var response = await fetch(`${apiUrl}/AccessGroups/GetAccessGroups`, {
                headers: {
                    "Authorization": `bearer ${jwt}`
                }
            })


            const result = await response.json();
            if (result.message != null) {
                window.alert(result.message);

            }
            else {
                setAccessGroup(result.data);
                setAccessGroups(result.data);
            }

        } catch (error) {
            console.error("Error fetching access groups:", error);
        }
    };

    const fetchFeatureFlags = async () => {
        try {
            var response = await fetch(`${apiUrl}/FeatureFlags/GetFeatureFlags`, {
                headers: {
                    "Authorization": `bearer ${jwt}`
                }
            })


            const result = await response.json();
            if (result.message != null) {
                window.alert(result.message);

            }
            else {
                setFeatureFlags(result);
                
            }

        } catch (error) {
            console.error("Error fetching Feature Flags:", error);
        }
    };

    const fetchHomePage = async () => {
        let v2Pages = ['screeningsummary', 'eventcontrol', 'managerreport'];
        try {
            v2Pages.includes(homePage) ? setHomePage('/' + homePage) : setHomePage('/V1/' + homePage);
        } catch (error) {
            console.error("Error setting homepage:", error);
        }
    };

    const fetchProfileData = async () => {

        try {

            const response = await fetch(`${apiUrl}/Login/GetProfileDetails`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${jwt}`
                },

            }).then(res => res.json()).then(data => {
                if (data.message) {
                    window.alert(data.message)
                }
                else {
                    setprofiledata({
                        firstName: data.data[0].firstName,
                        lastName: data.data[0].lastName,
                        Email: data.data[0].Email,
                        ProfilePic: data.data[0].ProfilePic,
                        Password: data.data[0].Password

                    });
                }
            })


        }
        catch (error) {
            console.error("Error fetching profile Data:", error);
        }
    }
    const handelToggleDarkmode = async () => {

        try {
            
            const response = await fetch(`${apiUrl}/Login/ToggleDarkModeflag`, {
                method: 'POST',
                headers: {
                    "Authorization": `bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(!isDarkflag ),
            });
            //console.log(JSON.stringify({ prodata }));
            if (response.status == 204 || response.statusText === "No Content") {
                window.alert("Failed To Switch");

            } else {
                const res = await response.json();
                if (res.message != null) {

                    window.alert(res.message);

                }
                else {
                    ontoggle();

                }
            }

        } catch (error) {
            console.error(error);
        }

    }

    const toggleSidebar = () => {
        const mainElement = document.querySelector('main');

        if (!isOpen) {
            mainElement.classList.add('dimmed-page');
        } else {
            mainElement.classList.remove('dimmed-page');
        }

        setIsOpen(!isOpen);
    };

    const toggleSubMenu = (id) => {
        setIsCollapsed({ ...isCollapsed, [id]: !isCollapsed[id] });
    };

    const navOptions = accessGroup.length > 0 && [
        {
            id: 1,
            title: 'Screening/Vetting',
            icon: screeningIcon,
            to: !featureFlags.ViewV2ScreeningSummary ? window.location.origin + '/V1/vetting_summary.aspx' : '/screeningsummary',
            access: accessGroup[0].newVettingSummaryCanView
        },
        /* Do not push this event control route to production server */
        //{
        //    id: 12,
        //    title: 'Event Control',
        //    icon: screeningIcon,
        //    to: '/event-control',
        //},
        {
            id: 2,
            title: 'Deployment & Operations',
            icon: deploymentIcon,
            access: accessGroup[0].newDeploymentSummaryCanView || accessGroup[0].newAnomalousShiftsCanView || accessGroup[0].newControlRoomCanView || accessGroup[0].newIncidentSummaryCanView,
            subNav: [
                {
                    id: 1,
                    title: 'Deployment Summary',
                    linkto: window.location.origin + '/V1/deployment_summary_beta.aspx',
                    access: accessGroup[0].newDeploymentSummaryCanView
                },
                {
                    id: 5,
                    title: 'Event Control',
                    linkto: !featureFlags.ViewV2EventControl ? window.location.origin : '/eventcontrol',
                    access: featureFlags.ViewV2EventControl
                },
                {
                    id: 2,
                    title: 'Anomalous Shifts',
                    linkto: window.location.origin + '/V1/anomalous_shifts.aspx',
                    access: accessGroup[0].newAnomalousShiftsCanView
                },
                {
                    id: 3,
                    title: 'Control Room',
                    linkto: window.location.origin + '/V1/control_room_beta.aspx',
                    access: accessGroup[0].newControlRoomCanView
                },
                {
                    id: 4,
                    title: 'Incidents/Occurences',
                    linkto: window.location.origin + '/V1/incident_summary.aspx',
                    access: accessGroup[0].newIncidentSummaryCanView
                },
            ],
        },
        {
            id: 3,
            title: 'Task Management',
            icon: taskIcon,
            linkto: window.location.origin + '/V1/tasks.aspx',
            access: accessGroup[0].newTasksIncidentCanView
        },
        {
            id: 4,
            title: 'Dashboards',
            icon: dashIcon, 
            linkto: window.location.origin + '/V1/pbi_dashboards.aspx',
            access: accessGroup[0].newDashboardCanView
        },
        {
            id: 5,
            title: 'Reporting',
            icon: reportsIcon,
            access: accessGroup[0].newReportingCanView,
            subNav: [
                {
                    id: 1,
                    title: 'Reports',
                    linkto: window.location.origin + '/V1/reporting.aspx',
                    access: accessGroup[0].newReportingCanView
                },
                {
                    id: 2,
                    title: 'Usage',
                    linkto: window.location.origin + '/V1/usage.aspx',
                    access: accessGroup[0].newReportingCanView
                },
            ],
        },
        {
            id: 6,
            title: 'Billing & Payroll',
            icon: billingIcon,
            access: accessGroup[0].newTimesheetsCanView || accessGroup[0].newAmmendmentsCanView || accessGroup[0].newInvoiceSummaryCanView || accessGroup[0].newImportHolidayCanView,
            subNav: [
                {
                    id: 1,
                    title: 'Timesheets',
                    linkto: window.location.origin + '/V1/timesheets.aspx',
                    access: accessGroup[0].newTimesheetsCanView
                },
                {
                    id: 2,
                    title: 'Amendments',
                    linkto: window.location.origin + '/V1/Amendments.aspx',
                    access: accessGroup[0].newAmmendmentsCanView
                },
                {
                    id: 3,
                    title: 'Invoice Summary',
                    linkto: window.location.origin + '/V1/invoice_summary.aspx',
                    access: accessGroup[0].newInvoiceSummaryCanView
                },
                {
                    id: 4,
                    title: 'Holiday Pay',
                    linkto: window.location.origin + '/V1/Import_Holiday.aspx',
                    access: accessGroup[0].newImportHolidayCanView
                },
            ],
        },
        {
            id: 7,
            title: 'Planner',
            icon: plannerIcon,
            linkto: window.location.origin + '/V1/Planner.aspx',
            access: accessGroup[0].newPlannerCanView
        },
        {
            id: 8,
            title: 'Form Definitions',
            icon: formIcon,
            linkto: window.location.origin + '/V1/form_definitions.aspx',
            access: accessGroup[0].newFormDefinitionsCanView
        },
        {
            id: 9,
            title: 'Settings',
            icon: settingsIcon,
            access: accessGroup[0].newSiteRecordsCanView ||
                accessGroup[0].newStaffRecordsCanView ||
                accessGroup[0].newMasterSettingsCanView ||
                accessGroup[0].newUploadDocumentsCanView ||
                accessGroup[0].newRateSummaryCanView ||
                accessGroup[0].newClientImportCanView ||
                accessGroup[0].newExportDataCanView,
            subNav: [
                {
                    id: 1,
                    title: 'Site Records',
                    linkto: window.location.origin + '/V1/site_records.aspx',
                    access: accessGroup[0].newSiteRecordsCanView
                },
                {
                    id: 2,
                    title: 'Staff Records',
                    linkto: window.location.origin + '/V1/staff_records.aspx',
                    access: accessGroup[0].newStaffRecordsCanView
                },
                {
                    id: 3,
                    title: 'Master Settings',
                    linkto: window.location.origin + '/V1/master_settings.aspx',
                    access: accessGroup[0].newMasterSettingsCanView
                },
                {
                    id: 4,
                    title: 'Upload Documents',
                    linkto: window.location.origin + '/V1/UploadDocuments.aspx',
                    access: accessGroup[0].newUploadDocumentsCanView
                },
                {
                    id: 5,
                    title: 'Rate Summary',
                    linkto: window.location.origin + '/V1/rate_summary.aspx',
                    access: accessGroup[0].newRateSummaryCanView
                },
                {
                    id: 6,
                    title: 'Import',
                    linkto: window.location.origin + '/V1/client_import.aspx',
                    access: accessGroup[0].newClientImportCanView
                },
                {
                    id: 7,
                    title: 'Export',
                    linkto: window.location.origin + '/V1/exportData.aspx',
                    access: accessGroup[0].newExportDataCanView
                },
            ],
        },
        {
            id: 10,
            title: 'Support Guides',
            icon: supportIcon,
            linkto: window.location.origin + '/V1/support_guides.aspx',
            access: accessGroup[0].newSuportGuidesCanView
        },
        {
            id: 11,
            title: 'Log Out',
            icon: logoutIcon,
            isLogout: true,
            access: true
        }
    ];

    // Logout function
    function logout() {
        return () => {
            sessionStorage.clear();
            localStorage.clear();

            // Remove cookie
            document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            document.cookie = 'coid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            document.cookie = 'userid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

            window.location.href = window.location.origin + '/V1/index.aspx';

        };
    }

    // re-direct to overview
    function adminOverView() {
        if (accessGroup["New_AdminOverview_CanView"]) {
            return () => {
                window.location.href = window.location.origin + '/V1/admin_overview.aspx';
            };
        }
    }

   


    function ProfileModal() {
        const [showProfile, setShowProfile] = useState(false);
        const [showPassword, setShowPassword] = useState(false);
        const [prodata, setprodata] = useState(profiledata);
        const [oldPassword, setOldPassword] = useState('');
        const [newPassword, setNewPassword] = useState('');
        const [confirmPassword, setConfirmPassword] = useState('');
        const UserId = cookies['userid'];
        const handleCloseProfile = () => setShowProfile(false);
        const handleShowProfile = () => setShowProfile(true);
        const [showConfirmPassword, setShowConfirmPassword] = useState(false);
        const handleClosePassword = () => {
            setShowProfile(true);
            setShowPassword(false);
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
        }
        const handleShowPassword = () => {
            setShowPassword(true);
            setShowProfile(false);
        }

        const handleProfileSubmit = async (event) => {
            event.preventDefault();
            if (username.toLocaleLowerCase() === "superadmin") {
                window.alert("Super Admin details cannnot be updated");
            }
            else {

                try {

                    const response = await fetch(`${apiUrl}/Login/UpdateProfileDetails`, {
                        method: 'PUT',
                        headers: {
                            "Authorization": `bearer ${jwt}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ prodata }),
                    });
                    // console.log(JSON.stringify({ prodata }));
                    if (response.status == 204 || response.statusText == "No Content") {
                        window.alert("No results found");

                    }
                    else {
                        const res = await response.json();
                        if (res.message != null) {

                            window.alert(res.message);

                        }
                        else {

                            fetchProfileData();
                            window.alert("Details updated successfully");
                        }



                    }

                } catch (error) {
                    console.error(error);
                }
            }
        };

        const HandlePasswordSubmit = async (event) => {
            event.preventDefault();
            if (oldPassword === prodata.Password) {

                if (newPassword == confirmPassword) {

                    try {

                        const response = await fetch(`${apiUrl}/Login/ChangePassword`, {
                            method: 'PATCH',
                            headers: {
                                "Authorization": `bearer ${jwt}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(newPassword),
                        });
                        //console.log(JSON.stringify({ prodata }));
                        if (response.status == 204 || response.statusText === "No Content") {
                            window.alert("No results found");

                        } else {
                            const res = await response.json();
                            if (res.message != null) {

                                window.alert(res.message);

                            }
                            else {
                                window.alert("Details updated successfully");
                                setOldPassword('');
                                setNewPassword('');
                                setConfirmPassword('');
                            }
                        }

                    } catch (error) {
                        console.error(error);
                    }

                }
                else {
                    window.alert("New Password and Confirm Password do not match");
                }


            }
            else {

                window.alert("Please Enter correct old Password");
            }


        };
        const handleChange = useCallback((e) => {
            setprodata(prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
            }));
        }, [setprodata]);

        
        return (
            <>
                <Button variant="primary" onClick={handleShowProfile}>
                    Edit Profile
                </Button>

                <Modal show={showProfile} onHide={handleCloseProfile} dialogClassName="fixedsize">
                    <Modal.Header closeButton>
                        <Modal.Title>Profile Update</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleProfileSubmit}>
                            <img src={prodata.ProfilePic.startsWith('data:image/jpeg;base64,') ? prodata.ProfilePic : `data:image/jpeg;base64,${prodata.ProfilePic}`} alt="Profile"
                                style={{ width: '100px', height: '100px', marginBottom: '20px' }} />
                            <Form.Group controlId="formFirstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    name="firstName"

                                    type="text"
                                    placeholder="Enter First Name"
                                    value={prodata.firstName}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="formLastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    name="lastName"
                                    type="text"
                                    placeholder="Enter Last Name"
                                    value={prodata.lastName}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="formLastName">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    name="Email"
                                    type="text"
                                    placeholder="Enter Email"
                                    value={prodata.Email}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Button variant="secondary" className="Updatebtnstyle" type="submit">
                                Update
                            </Button>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseProfile}>
                            Close
                        </Button>
                        <Button variant="danger" onClick={handleShowPassword} title={username.toLocaleLowerCase() === "superadmin" ? "Superadmin details cannot be updated" : null} disabled={username.toLocaleLowerCase() === "superadmin"}>
                            Update Password
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showPassword} onHide={handleClosePassword} dialogClassName="fixedsize">
                    <Modal.Header closeButton>
                        <Modal.Title>Password Update</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={HandlePasswordSubmit}>
                            <Form.Group controlId="formOldPassword">
                                <Form.Label>Old Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Old Password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="formNewPassword">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}

                                />
                                <a
                                    href="#!"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowConfirmPassword(!showConfirmPassword);
                                    }}
                                    style={{
                                        position: 'absolute',
                                        top: '39%',
                                        right: '27%',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {showConfirmPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
                                </a>
                            </Form.Group>

                            <Form.Group controlId="formConfirmPassword">
                                <Form.Label>Confirm New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Confirm New Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />

                            </Form.Group>

                            <Button variant="primary" className="Updatebtnstyle" type="submit">
                                Update Password
                            </Button>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClosePassword}>
                            Back
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    return accessGroup.length > 0 && (
        <header>
            {isIndexRoute ? (
                //don't show nav on index
                null
            ) : (
                    <>  
                        <div className="navBar w-100 p-4 fixed-top d-flex align-items-center justify-content-between">
                            <div className="d-flex">
                                <a href={window.location.origin + homePage}>
                                    <img width="40px;" height="40px" src={logo} alt="Opus Apiero"/>
                                </a>
                                <div className="button-open d-flex align-items-center ml-2">
                                    <NavbarToggler className={`sidebar-toggle ${isOpen ? "open" : ""}`} onClick={toggleSidebar}>
                                        {isOpen ? <BsXLg size={100} className="burger" /> : < GiHamburgerMenu size={24} className="burger" />}
                                    </NavbarToggler>
                                </div>
                            </div>
                            
                            <div className="go-dark d-flex">
                                <label className="me-2">{isDark ? "Switch to light mode" : "Switch to dark mode"}</label>
                        
                                <Form className="darkmode-toggle">
                                    <Form.Check
                                        type="switch"
                                        id="go-dark"
                                        checked={isDark}
                                        onChange={handelToggleDarkmode}
                                    />
                                </Form>
                            </div>
                        </div>

                        <section className={`sidebar-container ${isOpen ? "open" : ""}`}>      
                            <Nav vertical className="sidebar-menu-container">
                                <div className="sidebar-menu">
                                    {navOptions.filter((e) => e.access == true).map((navItem) => (
                                        <NavItem key={navItem.id} onClick={navItem.isLogout ? logout() : () => toggleSubMenu(navItem.id)}>
                                            {/*<span data-bs-toggle="tooltip" data-bs-placement="right" title={navItem.title}>*/}
                                            {/*    <img src={navItem.icon} alt={navItem.title} />*/}
                                            {/*</span>*/}
                                            {navItem.subNav ? (
                                                <>
                                                <NavLink>     
                                                    {navItem.title}
                                                </NavLink>
                                                <Collapse isOpen={isCollapsed[navItem.id]}>
                                                    <Nav>
                                                        {navItem.subNav.filter((e) => e.access == true).map((subNavItem) => (
                                                            <NavItem key={subNavItem.id}>
                                                                {subNavItem.linkto ? (
                                                                    <a className="nav-link nav-link-text" href={subNavItem.linkto} onClick={toggleSidebar}>{subNavItem.title}</a>
                                                                ) : (
                                                                    <Link to={subNavItem.to} className="nav-link-text">
                                                                        <div className="link-arrow">
                                                                            <RiArrowDropRightLine size={30} />
                                                                        </div>
                                                                        <NavLink onClick={toggleSidebar}>
                                                                            {subNavItem.title}
                                                                        </NavLink>
                                                                    </Link>
                                                                )}
                                                            </NavItem>
                                                        ))}
                                                    </Nav>
                                                </Collapse>
                                            </>
                                            ) : (
                                                navItem.linkto ? (
                                                    <a className="nav-link nav-link-text" href={navItem.linkto} onClick={toggleSidebar}>{navItem.title}</a>
                                                ) : (
                                                    <Link className="nav-link nav-link-text" to={navItem.to} onClick={toggleSidebar}>{navItem.title}</Link>

                                                )
                                            )}
                                        </NavItem>
                                    ))}
                                </div>
                                <div className="sidebar-header" onClick={adminOverView()}>
                                    <div className="HoldCenter d-flex align-items-center">
                                        {profiledata && <img src={profiledata.ProfilePic.startsWith('data:image/jpeg;base64,') ? profiledata.ProfilePic : defaultAvatar} alt="Profile"
                                            style={{ width: '55px', height: '55px', borderRadius: '85px', marginRight: '1rem', marginLeft: '0.6rem' }} />}
                                        <div className="d-flex flex-column">
                                            <h5>{profiledata.firstName + ' ' + profiledata.lastName}</h5>
                                            {username.toLocaleLowerCase() !== "superadmin" && < ProfileModal />}
                                        </div>
                                    </div>
                                </div>
                                
                            </Nav>
                            
                        </section>
                        
                </>
            )}
            
        </header>
    );
}
